.slick-next:before,
.slick-prev:before {
  color: #b9adad !important;
}

body {
  font-family: "Poppins", sans-serif;
  /* background-image: linear-gradient(to right bottom, #fde3ec6c, #ffbfb860); */
  color: #380e1d;
}
.logo {
  /* box-shadow: 4px 4px 20px 4px rgba(54, 31, 31, 0.336); */
}
.m-nav {
  margin-top: 100px !important;
}
img {
  width: 100%;
}

.section {
  padding: 40px;
}

.hero {
  min-height: 80vh;
  /* background: #888; */
  background-image: linear-gradient(to right bottom, #cb356b, #bd3f32);
  background-size: cover;
  background-position: top;
  /* clip-path: polygon(0 0, 100% 0%, 100% 75vh, 0 100%); */
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
  position: relative;
}
.dealer {
  display: flex;
  height: 60px;
  width: 140px;
  background: rgb(100, 0, 0);
  color: aliceblue;
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-weight: bold;
  position: fixed;
  bottom: 120px;
  right: -10px;
  box-shadow: 4px 4px 20px 4px rgba(54, 31, 31, 0.336);
}
.whatsapp {
  height: 80px;
  width: 80px;
  background: transparent;
  border-radius: 50%;
  /* box-shadow: 4px 4px 20px 4px rgba(54, 31, 31, 0.336); */
  position: fixed;
  bottom: 120px;
  left: 10px;
}

.dash {
  /* content: " "; */
  text-align: center;
  width: 70px;
  height: 6px;
  background-color: rgb(119, 8, 8);
}

.dash1 {
  /* content: " "; */
  text-align: center;
  width: 140px;
  height: 8px;
  background-color: rgb(119, 8, 8);
}

.on_top {
  z-index: 88;
}

.product {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* border: 10px solid red; */
  margin-bottom: 20px;
  width: 90%;
}
.product .text-box {
  background: rgba(255, 201, 201, 0.151);
}

#t-title {
  margin-bottom: 140px;
}

.tcard {
  margin-top: 140px;

  padding: 40px;
  background: rgba(255, 201, 201, 0.151);
  /* box-shadow: 4px 4px 20px 4px rgba(54, 31, 31, 0.336); */
  border: 2px solid rgba(255, 0, 0, 0.137);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  margin-bottom: 40px;
}
.tbody {
  font-size: 26px;
  line-height: 40px;
}

.img-box {
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  margin-top: -140px;
  margin-bottom: 40px;
}
.footer {
  background: #380000;
}
.footer a {
  text-decoration: none;
  color: aliceblue;
}

.footer p {
  padding: 0;
  margin: 0;
}

.about-us .header {
  background: url(./img/aboutUsBG.png);
  height: 50vh;
}
.product-page-features-card {
  max-width: 100%;
  min-width: 240px;
  margin-bottom: 20px;
}

.related-product-card {
  max-width: 10%;
  min-width: 240px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .related-product-card {
    max-width: 100%;
  }
  .hero-top-margin {
    margin-top: 80px;
  }
}

.border-table {
  border: 4px solid rgba(255, 0, 0, 0.151);
  border-radius: 2px;
}

.contact-header {
  min-height: 50vh;
  /* background-color: #b9adad; */
}
.contact-header {
  border: 1px sold #4dc247;
  background-image: url("./img/contact-bg.png");
  background-attachment: fixed;
  /* background-size: 1400px; */

  background-position: center;
}

.form-new-dealer {
  margin-top: 120px;
}
